.stepper-container {
    background-color: #f3faff;
    max-width: 360px;
    max-height: 130px;
    padding: 50px 170px;
    margin-bottom: 20px;
    border-radius: 10px;
}

.circle-1 {
    --size: 25px;
    width: var(--size);
    height: var(--size);
    border-radius: 50%;
    z-index: 1;
    background-color: var(--gray30);
    cursor: pointer;
    margin-bottom: 10px;
    border: none;
}

.circle-1:disabled {
    cursor: auto;
}

.line {
    height: 4px;
    width: 100px;
    background-color: var(--light-blue);
    position: relative;
    top: -12px;
}

.circle-container {
    margin: 0px -20px;
}

@media screen and (max-width: 500px) {
    .stepper-container {
        padding: 30px 20px;
    }
}
