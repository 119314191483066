@import url("https://fonts.googleapis.com/css2?family=Barlow+Semi+Condensed:wght@500;600&family=Inter:wght@500;700&display=swap");
:root {
    --red: #d91a28;
    --navy-blue: #243672;
    --light-blue: #dbeeff;
    --gray: #dadada;
    --gray30: #b4b4b4;
    --gray50: #7e7e7e;
    --off-white: #f3faff;
    --accent-blue: #133dc9;
}

body {
    height: 100vh;
}

* {
    font-family: "Inter", sans-serif;
    margin: 0;
    padding: 0;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.font-color-blue {
    color: var(--navy-blue);
}

.font-color-gray {
    color: var(--gray);
}

.heading-big {
    font-weight: 500;
    font-size: 35px;
    line-height: 42px;
}

.heading {
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
}

.title {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
}

.subtitle {
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
}

.body-1 {
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
}

.body-2 {
    font-weight: 700;
    font-size: 16px;
    line-height: 30px;
}

.caption {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.03em;
}

.overline {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.2em;
    text-transform: uppercase;
}

.small {
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    letter-spacing: 0.03em;
}

.button {
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.03em;
}

.flex-container {
    display: flex;
    height: 100vh;
}

.button-red {
    color: #ffffff;
    background-color: var(--red);
    border-radius: 5px;
    border: 0;
    padding: 12px 35px;
    cursor: pointer;
}

.button-red:disabled {
    background-color: var(--gray);
    cursor: auto;
}

.gov-id-input {
    outline: 0;
    border-width: 0 0 1px;
    border-color: black;
    font-size: 48px;
    padding: 15px;
    max-width: 800px;
    margin-bottom: 20px;
}

.exit-button {
    color: #d91a28;
    margin-right: 10px;
}

.header-container {
    display: "flex";
    justify-content: "space-between";
    align-items: "center";
    width: 100%;
    height: 60px;
}

.flex {
    display: flex;
    flex-direction: row;
}

.flex-column {
    flex-direction: column;
}

.justify-content-center {
    justify-content: center;
}

.justify-content-between {
    justify-content: space-between;
}

.justify-content-start {
    justify-content: flex-start;
}

.align-items-center {
    align-items: center;
}

.gov-id-input-container {
    height: calc(100vh - 120px);
}

.circle {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: var(--navy-blue);
    color: #ffffff;
}

.question-card {
    padding: 25px 40px;
    border: 2px solid var(--off-white);
    border-radius: 10px;
    margin-bottom: 40px;
    width: 100%;
    max-width: 616px;
}

.font-gray {
    color: var(--gray50);
}

.font-navy {
    color: var(--navy-blue);
}

.question-input {
    outline: 0;
    border-width: 0 0 1px;
    border-color: var(--gray30);
    width: 100%;
}

.question-check {
    width: 15px;
    height: 15px;
    border-color: var(--gray30);
    border-radius: 10px;
}

.button-blue {
    color: #ffffff;
    background-color: var(--accent-blue);
    border-radius: 5px;
    border: 0;
    padding: 12px 35px;
    cursor: pointer;
}

.button-blue:disabled {
    background-color: var(--gray);
    cursor: auto;
}

.button-light-blue {
    color: var(--accent-blue);
    background-color: var(--light-blue);
    border-radius: 5px;
    border: 0;
    padding: 12px 35px;
    cursor: pointer;
}

.button-light-blue:disabled {
    background-color: var(--gray);
    cursor: auto;
    color: var(--gray50);
}

input[type="radio"] {
    appearance: none;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    outline: none;
    border: 2px solid var(--light-blue);
    margin-right: 10px;
}

input[type="radio"]:before {
    content: "";
    display: block;
    width: 60%;
    height: 60%;
    margin: 20% auto;
    border-radius: 50%;
}

input[type="radio"]:checked:before {
    background: var(--navy-blue);
}

input[type="radio"]:checked {
    border-color: var(--light-blue);
}

fieldset {
    border: none;
}

.mb-1 {
    margin-bottom: 10px;
}

.mb-2 {
    margin-bottom: 20px;
}

.mb-3 {
    margin-bottom: 30px;
}
.mb-4 {
    margin-bottom: 40px;
}

.initial-logo {
    max-width: 400px;
    margin-bottom: 100px;
}

@media screen and (max-width: 450px) {
    .initial-logo {
        max-width: 300px;
    }

    .gov-id-input {
        max-width: 300px;
        font-size: 24px;
    }

    .question-card {
        padding: 10px 30px;
    }
}
